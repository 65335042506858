<template>
  <v-container>
    <v-layout row wrap>
      <v-flex>
        <v-layout row wrap>
          <template
            v-if="row.action === 'delete_companyTerms_detail' && !row.isDefault"
          >
            <BaseDeletion
              :close="close"
              :collection-name="'companyTerm'"
              :row="row"
              :special-identifier="'companyTermsId'"
              :action="action"
            />
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseDeletion from '@/components/BaseDeletion.vue'
import { yToIe } from '@/utils/string'
import companyTerms from '@/services/companyTerms'

export default {
  components: {
    BaseDeletion,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      action: (params) => companyTerms.deleteCompanyTerm(params),
    }
  },
  methods: {
    deleteTarget() {
      this.$store
        .dispatch(
          `${yToIe(
            this.collectionName
          )}s/delete${this.collectionName.replace(/^./, (str) =>
            str.toUpperCase()
          )}`,
          this.row[this.specialIdentifier] ||
            this.row[`${this.collectionName}Id`]
        )
        .then(() => {
          this.close()
        })
    },
    close() {
      this.props.expanded = false
    },
  },
}
</script>
