<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Company Terms</h1>
  </BaseTableWrapper>
</template>

<script>
import companyTerms from '@/services/companyTerms'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import CompanyTermsDetail from '@/components/CompanyTermsDetail.vue'
import { mapActions } from 'vuex'
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'
import { datePredefined } from '@/utils/predefined'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'companyTerms',
  ['view-company-terms', 'edit-company-terms', 'delete'],
  'companyTerms'
)
const columns = [
  {
    _t_id: '1634c189',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: 'ff1fe6c9',
    prop: 'companyTermsId',
    text: 'ID',
    sort: true,
    filter: true,
    type: 'number',
    filterType: 'eq',
    sortProp: 'companyTermsId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '863e977e',
    prop: 'label',
    text: 'Name',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'label',
    detail: false,
  },
  {
    _t_id: 'bb4cb2bf',
    prop: 'isDefault',
    text: 'Default',
    computedText: (item) => (item ? 'Yes' : 'No'),
    sort: false,
    filter: false,
    defaultHidden: false,
    detail: false,
  },
  {
    _t_id: '70d2bc27',
    prop: 'createdOn',
    text: 'Created Date',
    computedText: (item) =>
      item === null
        ? ''
        : DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
    sort: true,
    filter: true,
    type: 'date',
    method: 'and',
    childMethod: 'and',
    filterType: 'eq',
    defaultHidden: false,
    predefined: deepClone(datePredefined),
    detail: false,
  },
  {
    _t_id: '28c490b8',
    prop: 'updatedOn',
    text: 'Updated Date',
    computedText: (item) =>
      item === null
        ? ''
        : DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
    sort: true,
    filter: true,
    type: 'date',
    method: 'and',
    childMethod: 'and',
    filterType: 'eq',
    defaultHidden: false,
    predefined: deepClone(datePredefined),
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        addNewEnabled: true,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        enableExport: false,
        externalShareFilters: this.receiveFilters,
        externalShareSorts: this.receiveSorts,
        addNewHandler: () => this.$router.push({ name: 'company-terms.add' }),
        detail: CompanyTermsDetail,
        detailKeyId: 'companyTermsId',
        tableId: 'company_terms_tv_view',
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'CompanyTerms',
        action: (params) => companyTerms.getCompanyTerms(params),
      },
    }
  },
  methods: {
    ...mapActions({}),
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
  },
}
</script>
